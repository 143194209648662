'use client';

import { useTranslation } from '@/app/i18n/client';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { PartnerSettings } from '@/lib/partners/settings/types/PartnerSettings';

const Footer = ({
  partner,
  extraFooterCopy = '',
  footerHeadline = '',
}: {
  partner: string;
  extraFooterCopy?: string;
  footerHeadline?: string;
}) => {
  const { t } = useTranslation(); // this is using the standard client load of translations
  const partnerSettings = getPartnerSettings(partner);

  return (
    <div className="max-w-[1110px] px-6 laptop:px-8 mx-auto my-4">
      {footerHeadline !== '' ? (
        <p className="my-4 text-xs text-core-7 leading-5 ">{footerHeadline}</p>
      ) : null}
      <p className="text-xs text-core-7 leading-5 ">{t('pp-footer-part-1')}</p>
      {extraFooterCopy !== '' ? (
        <p className="mt-4 text-xs text-core-7 leading-5 ">{extraFooterCopy}</p>
      ) : null}
      <div className="footer__links mt-6 flex flex-col laptop:flex-row">
        <a
          className="underline text-core-7 mr-2 mobile:mr-16 text-xs font-bold mb-4 hover:text-core-8 "
          href={partnerSettings['privacy-policy-url']}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('pp-privacy-policy')}
        </a>
        {partnerSettings['terms-and-conditions-url'] && (
          <a
            className="underline text-core-7 mr-2 mobile:mr-16 text-xs font-bold  mb-4 hover:text-core-8"
            href={partnerSettings['terms-and-conditions-url']}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('pp-terms-and-conditions')}
          </a>
        )}
        <a
          className="underline text-core-7 text-xs font-bold mb-4 hover:text-core-8"
          href={partnerSettings['cookie-policy-url']}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('pp-cookie-policy')}
        </a>
      </div>
    </div>
  );
};

export default Footer;
