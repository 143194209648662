import { useParams } from 'next/navigation';
import React from 'react';

const TrustBox = ({
  className,
  height = '90px',
  width = '150px',
  theme = 'light',
}: {
  className?: string;
  height?: string;
  width?: string;
  theme?: string;
}) => {
  const params = useParams();

  const locale = params?.lang ?? 'en-GB';

  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      className={`trustpilot-widget ${
        className ? className : ' min-h-[90px] min-w-[150px]'
      }`}
      data-locale={locale}
      data-theme={theme}
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="5f3a881ba4989100019608f7"
      data-style-height={height}
      data-style-width={width}
    >
      <a
        href="https://www.trustpilot.com/review/example.com"
        target="_blank"
        rel="noopener"
      >
        {' '}
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
