import Trustbox from '@/app/[lang]/[partner]/components/Trustbox';
import { Button } from '@/app/components/Button';
import { Chevron } from '@/app/components/icons/Chevron';
import { getPartnerSettings } from '@/lib/partners/settings/getSettings';
import { usePartner } from '@/lib/partners/usePartner/client';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

const Hero = () => {
  const { t } = useTranslation();
  const partner = usePartner();
  const partnerSettings = getPartnerSettings(partner);
  return (
    <aside className="relative flex flex-col w-full flex-1">
      <div className="flex-1 mb-8 rounded-2xl overflow-hidden relative min-h-[448px]">
        <Image
          src={`${getEnvVar(
            'NEXT_PUBLIC_CDN',
          )}/images/liberisCore/EBAY_USA/image_right.png`}
          alt="eBay and Liberis "
          className="w-full rounded-tl-3xl rounded-tr-3xl object-cover"
          aria-hidden={true}
          fill
        />
      </div>
      <section className="z-10">
        <div className="w-full rounded-2xl bg-primary text-core-1 flex flex-1">
          <div className="flex-1 px-9 py-11">
            <h2 className="text-lg mb-5">{t('pp-cs-header')}</h2>
            <p className="text-sm leading-5 mb-5 text-pretty max-w-md ">
              {t('pp-cs-copy')}
            </p>
            <Button
              implementAsAnchor={true}
              href={partnerSettings.faqsLink}
              id="ReturnToFAQs"
              mixpanelButtonText="Return to FAQs"
              className="bg-transparent font-medium hover:underline"
              target="_blank"
            >
              {t('pp-cs-return-to-faqs-text')}&nbsp;
              <span className="inline-block w-6 relative top-[6px]">
                <Chevron />
              </span>
            </Button>
          </div>
          <div className="min-w-[33%] max-w-[40%] content-center bg-darkenedPrimary px-9 py-11 rounded-tr-2xl rounded-br-2xl">
            <div className="mx-auto top-[20px] relative max-w-[180px]">
              <Trustbox
                className="w-full max-w-48"
                width="100%"
                height=""
                theme="dark"
              />
            </div>
          </div>
        </div>
      </section>
    </aside>
  );
};

export { Hero };
